import React, { useState } from 'react';
import { Field, FieldArray } from 'formik';

import CustomInput from '../input/CustomInput';
import CustomSearch from '../input/CustomSearch';
import CustomButton from '../button/CustomButton';
import CustomTextArea from '../input/CustomTextArea';
import CustomInputClock from '../input/CustomInputClock';
import CustomInputDropdown from '../input/CustomInputDropdown';
import AddExerciseModal from '../add-exercise-modal.jsx/AddExerciseModal';
import {
  WorkoutBlockType,
  BlockExerciseUnit,
  BlockExerciseWeightType,
} from '../../constants/enums';

import { useExercise } from '../../hooks';

import {
  WorkoutBlockForm,
  WorkoutBlockHeader,
  WorkoutBlockContent,
  AttributeWrapper,
  AttributeBullet,
  AttributeLabel,
  WorkoutBlockFooter,
  WorkoutBlockApplyButton,
  AttrCheckBox,
  ExerciseWrapper,
  ExerciseIndex,
  ExerciseContent,
  WorkoutBlockHeaderIconsWrapper,
  WorkoutBlockHeaderIcon,
  DeleteExerciseIcon,
  FlexWrapper,
  AsNeeded,
  AsNeededCheck,
  AttributeUnit,
} from './components';
import Exercise from './Exercise';

const EdittingWorkoutBlock = ({
  block,
  index,
  setFieldValue,
  errors,
  touched,
  deleteSelf,
  setTouched,
}) => {
  const { exercises, exercisesLoading, searchText, setSearchText, createNewExercise, loading } =
    useExercise();
  const [exerciseAdding, setExerciseAdding] = useState(null);

  const swapExercises = (blockIndex, x, y) => {
    const exercises = [...block.exercises];
    const tempExercise = exercises[x];
    exercises[x] = exercises[y];
    exercises[y] = tempExercise;
    setFieldValue(`blocks[${index}].exercises`, exercises);
  };

  // console.log('all', JSON.stringify(exercises?.getExercises));

  return (
    <WorkoutBlockForm>
      <WorkoutBlockHeader>
        {block?.name}
        <WorkoutBlockHeaderIconsWrapper>
          <WorkoutBlockHeaderIcon onClick={deleteSelf} />
        </WorkoutBlockHeaderIconsWrapper>
      </WorkoutBlockHeader>
      <WorkoutBlockContent>
        {block?.attributes?.map(
          (attr, i) =>
            attr?.name !== 'unit' && (
              <AttributeWrapper key={i} className={attr.name}>
                <AttributeLabel small={attr.name === 'rest' || attr.type === 'text'}>
                  <AttributeBullet clock={attr?.type === 'clock'} />
                  {attr?.label}
                </AttributeLabel>

                <Field
                  id={`blocks[${index}].attributes[${i}].value`}
                  name={`blocks[${index}].attributes[${i}].value`}
                >
                  {({ field }) => (
                    <>
                      {attr?.type === 'clock' ? (
                        <AsNeeded>
                          <CustomInputClock
                            height="4rem"
                            onChange={(v) => {
                              setFieldValue(field.name, v);
                              if (block?.exercises?.length > 0 && attr.name === 'workTime') {
                                const [, workM, workS] = v?.split(':');
                                const quantity = Number(workM) * 60 + Number(workS);
                                block.exercises.map((ex, j) => {
                                  setFieldValue(
                                    `blocks[${index}].exercises[${j}].quantity`,
                                    quantity,
                                  );
                                });
                              }
                            }}
                            disabled={attr?.asNeeded}
                            value={attr?.asNeeded ? undefined : field.value}
                            error={
                              touched?.blocks?.[index] &&
                              errors?.blocks?.[index]?.attributes?.[i]?.value
                            }
                          />
                          {typeof attr?.asNeeded === 'boolean' && (
                            <AsNeeded>
                              <AsNeededCheck
                                checked={attr.asNeeded}
                                onClick={() =>
                                  setFieldValue(
                                    `blocks[${index}].attributes[${i}].asNeeded`,
                                    !attr.asNeeded,
                                  )
                                }
                              />
                              As Needed
                            </AsNeeded>
                          )}
                        </AsNeeded>
                      ) : attr?.name === 'text' ? (
                        <CustomTextArea
                          rowCount={5}
                          style={{
                            padding: '8px',
                          }}
                          placeholder="Type here..."
                          wrapperStyle={{
                            maxWidth: '100%',
                          }}
                          onChange={(v) => setFieldValue(field.name, v)}
                          value={field.value}
                          errorIcon={null}
                          errorBorder
                          error={
                            !!(
                              touched?.blocks?.[index] &&
                              errors?.blocks?.[index]?.attributes?.[i]?.value
                            )
                          }
                        />
                      ) : (
                        <>
                          <CustomInput
                            type={attr?.type === 'text' ? 'text' : 'number'}
                            onChange={(v) => {
                              setFieldValue(field.name, v);
                              if (
                                block?.exercises?.length &&
                                block?.type === WorkoutBlockType.STRENGTH &&
                                attr?.name === 'sets' &&
                                v > 0
                              ) {
                                if (block?.exercises?.length > v) {
                                  setFieldValue(
                                    `blocks[${index}].exercises`,
                                    block?.exercises?.slice(0, v),
                                  );
                                } else if (block?.exercises?.length < v) {
                                  const exercisesToAdd = new Array(
                                    v - block?.exercises?.length,
                                  ).fill({
                                    ...block?.exercises?.[0],
                                    quantity: 1,
                                    weight: 0,
                                    unit: BlockExerciseUnit.REPS,
                                    weightType: BlockExerciseWeightType.KG,
                                  });
                                  setFieldValue(`blocks[${index}].exercises`, [
                                    ...block?.exercises,
                                    ...exercisesToAdd,
                                  ]);
                                }
                              }

                              // if (v > 0 && block?.uniqueExercises) {
                              //   if (block?.exercises?.length > v) {
                              //     setFieldValue(
                              //       `blocks[${index}].exercises`,
                              //       block?.exercises?.slice(0, v),
                              //     );
                              //   } else if (block?.exercises?.length < v) {
                              //     const exercisesToAdd = new Array(v - block?.exercises?.length).fill(
                              //       {
                              //         quantity: 1,
                              //         weight: 0,
                              //         unit: BlockExerciseUnit.REPS,
                              //         weightType: BlockExerciseWeightType.KG,
                              //       },
                              //     );
                              //     setFieldValue(`blocks[${index}].exercises`, [
                              //       ...block?.exercises,
                              //       ...exercisesToAdd,
                              //     ]);
                              //   }
                              // }
                            }}
                            outlined
                            height="4rem"
                            borderRadius="8px"
                            value={field.value}
                            style={{
                              width: '9.4rem',
                              margin: 0,
                              textAlign: attr?.type === 'text' ? 'left' : 'center',
                              outline: 'none',
                              fontFamily: 'Space-r',
                              flexGrow: attr?.type === 'text' ? '1' : '0',
                            }}
                            borderColor="transparent"
                            focusBorderColor="transparent"
                            backgroundColor="#f1f0f0cc"
                            error={
                              touched?.blocks?.[index] &&
                              !!errors?.blocks?.[index]?.attributes?.[i]?.value
                            }
                            errorIcon={false}
                          />
                        </>
                      )}
                    </>
                  )}
                </Field>
                {/* {attr?.name === 'pace' && (
                <Field
                  id={`blocks[${index}].attributes[${i}].textValue`}
                  name={`blocks[${index}].attributes[${i}].textValue`}
                >
                  {({ field }) => (
                    <CustomInputDropdown
                      style={{
                        width: '11.3rem',
                        minHeight: '4rem',
                        margin: '0',
                        marginLeft: '0.8rem',
                        height: '4rem',
                        'text-transform': 'lowercase',
                      }}
                      borderRadius="8px"
                      options={['km/h', 'min/km']}
                      onChange={(v) => setFieldValue(field.name, v)}
                      value={field.value}
                      placeholder="-unit-"
                      error={
                        touched?.blocks?.[index] && !!errors?.blocks?.[index]?.exercises?.[i]?.unit
                      }
                    />
                  )}
                </Field>
              )} */}
                {attr?.name === 'unit' && attr?.textValue === 'Distance' && (
                  <AttributeUnit>meters</AttributeUnit>
                )}
              </AttributeWrapper>
            ),
        )}
        {block?.type !== WorkoutBlockType.REST && block?.type !== WorkoutBlockType.CUSTOM && (
          <>
            <FieldArray
              name={`blocks[${index}].exercises`}
              render={({ push, remove }) => (
                <>
                  <AddExerciseModal
                    modalOpen={!!exerciseAdding}
                    closeModal={(initialSearch) => {
                      setExerciseAdding(null);
                      setSearchText(initialSearch);
                    }}
                    submitModal={async (v, edited, editedGlobal) => {
                      const exercise = await createNewExercise(v);
                      if (edited && editedGlobal) {
                        const exerciseIndex = block.exercises.findIndex(
                          (e) => e.exercise.id === exercise.editedExercise,
                        );
                        setFieldValue(
                          `blocks[${index}].exercises[${exerciseIndex}].exercise`,
                          exercise,
                        );
                        return;
                      } else if (edited) {
                        return;
                      }
                      if (block?.type === WorkoutBlockType.STRENGTH) {
                        const setsQuantity = block?.attributes?.find(
                          (x) => (x.name = 'sets'),
                        )?.value;
                        const exercisesToAdd = new Array(Number(setsQuantity)).fill({
                          exercise: { ...v, ...exercise },
                          quantity: 1,
                          weight: 0,
                          unit: BlockExerciseUnit.SECONDS,
                          weightType: BlockExerciseWeightType.KG,
                        });
                        setFieldValue(`blocks[${index}].exercises`, exercisesToAdd);
                      } else if (block?.type === WorkoutBlockType.PILATESR) {
                        push({
                          exercise: { ...v, ...exercise },
                          setting: v.setting || "",
                          category: v.category || "",
                          quantity: 1,
                          weight: 0,
                          notes: "",
                          reps: "1",
                          unit: BlockExerciseUnit.REPS,
                          weightType: BlockExerciseWeightType.KG,
                        });
                      } else {
                        const numberOfSets =
                          block?.type === WorkoutBlockType.CLASSICSTRENGTH ? 3 : null;
                        const restTime =
                          block?.type === WorkoutBlockType.CLASSICSTRENGTH ? '00:00:30' : null;
                        push({
                          exercise: { ...v, ...exercise },
                          quantity: 1,
                          weight: 0,
                          notes: "",
                          unit: BlockExerciseUnit.SECONDS,
                          weightType: BlockExerciseWeightType.KG,
                          numberOfSets,
                          restTime,
                        });
                      }
                    }}
                    initialValues={exerciseAdding}
                    loading={loading}
                  />
                  {block?.type === WorkoutBlockType.STRENGTH && !!block?.exercises?.length && (
                    <AttributeWrapper>
                      <FlexWrapper>
                        <AttributeLabel>
                          <AttributeBullet />
                          {block?.exercises?.[0].exercise?.title || false}
                        </AttributeLabel>
                        <DeleteExerciseIcon
                          nonAbsolute
                          onClick={() => setFieldValue(`blocks[${index}].exercises`, [])}
                        />
                      </FlexWrapper>
                    </AttributeWrapper>
                  )}
                  {block?.exercises?.map((ex, i) => (
                    <Exercise
                      block={block}
                      i={i}
                      key={i}
                      index={index}
                      ex={ex}
                      swapExercises={swapExercises}
                      setExerciseAdding={setExerciseAdding}
                      setFieldValue={setFieldValue}
                      touched={touched}
                      errors={errors}
                      remove={remove}
                    />
                  ))}
                  {/* {block?.uniqueExercises &&
                    block?.exercises?.map((ex, subIndex) =>
                      ex.exercise ? (
                        <Exercise
                          block={block}
                          i={subIndex}
                          index={index}
                          ex={ex}
                          swapExercises={swapExercises}
                          setExerciseAdding={setExerciseAdding}
                          setFieldValue={setFieldValue}
                          touched={touched}
                          errors={errors}
                          remove={(v) => {
                            const { exercise, ...rest } = v;
                            setFieldValue(`blocks[${index}].exercises[${subIndex}]`, rest);
                          }}
                        />
                      ) : (
                        <ExerciseWrapper>
                          <ExerciseIndex>
                            {block?.indexPrefix} {subIndex + 1}
                          </ExerciseIndex>
                          <ExerciseContent>
                            {(block?.type !== WorkoutBlockType.STRENGTH ||
                              !block?.exercises?.length) && (
                              <indexPrefix
                                onSelect={(v) => {
                                  setFieldValue(`blocks[${index}].exercises[${subIndex}]`, {
                                    exercise: v,
                                    quantity: 1,
                                    weight: 0,
                                    unit: BlockExerciseUnit.SECONDS,
                                    weightType: BlockExerciseWeightType.KG,
                                  });
                                }}
                                createNew={(searchInput) =>
                                  setExerciseAdding({ title: searchInput, searchText })
                                }
                                viewOption={(option) =>
                                  setExerciseAdding({ ...option, searchText })
                                }
                                error={Boolean(
                                  touched?.blocks?.[index] &&
                                    errors?.blocks?.[index]?.exercises[subIndex],
                                )}
                              />
                            )}
                          </ExerciseContent>
                        </ExerciseWrapper>
                      ),
                    )} */}
                  {/* {!block?.uniqueExercises && ( */}
                  <ExerciseWrapper>
                    <ExerciseIndex>
                      {block?.type !== WorkoutBlockType.STRENGTH && block?.exercises?.length + 1}
                    </ExerciseIndex>
                    {block?.type === WorkoutBlockType.RUN && (
                      <ExerciseContent>
                        <CustomInputDropdown
                          placeholder="Choose Exercise"
                          value={''}
                          returnEntire
                          onChange={(v) => {
                            push({
                              exercise: v,
                              quantity: 1,
                              weight: 0,
                              unit: BlockExerciseUnit.SECONDS,
                              weightType: BlockExerciseWeightType.KG,
                            });
                          }}
                          loading={exercisesLoading}
                          options={exercises?.getExercises
                            ?.filter(
                              (x) =>
                                !!{ run: true, walk: true, rest: true }[x.title?.toLowerCase()])
                            .map((ex) => ({
                              ...ex,
                              label: ex.title,
                              value: ex.uid,
                            }))}
                          style={{ margin: '0', maxHeight: '4rem' }}
                          error={
                            !block?.exercises?.length &&
                            touched?.blocks?.[index] &&
                            errors?.blocks?.[index]?.exercises
                          }
                        />
                      </ExerciseContent>
                    )}
                    {block?.type === WorkoutBlockType.PILATESR && (
                        <ExerciseContent>
                          <CustomSearch
                              placeholder="Search Exercise"
                              onChange={(v, { action }) => {
                                if (action === 'input-change' || action === 'set-value') {
                                  setSearchText(v);
                                }
                              }}
                              onSelect={(v) => {
                                  push({
                                    exercise: v,
                                    setting: v.setting || "",
                                    reps: "3",
                                    notes: "",
                                    quantity: 1,
                                    weight: 0,
                                    unit: BlockExerciseUnit.REPS,
                                    weightType: BlockExerciseWeightType.KG,
                                  });
                              }}
                              createNew={(searchInput) =>
                                  setExerciseAdding({ title: searchInput, searchText, type: WorkoutBlockType.PILATESR})
                              }
                              viewOption={(option) => setExerciseAdding({ ...option, searchText })}
                              options={exercises?.getExercises?.filter(
                                          (x) =>
                                              !!{ "pilatesr": true}[x.type?.toLowerCase()]) || []}
                              value={searchText || ''}
                              style={{ margin: '0' }}
                              loading={exercisesLoading}
                              error={
                                  !block?.exercises?.length &&
                                  touched?.blocks?.[index] &&
                                  errors?.blocks?.[index]?.exercises
                              }
                          />
                          {/*<CustomInputDropdown*/}
                          {/*    placeholder="Choose Exercise"*/}
                          {/*    value={''}*/}
                          {/*    returnEntire*/}
                          {/*    onChange={(v) => {*/}
                          {/*      push({*/}
                          {/*        exercise: v,*/}
                          {/*        setting: "bla",*/}
                          {/*        reps: "3",*/}
                          {/*        notes: "notes bla",*/}
                          {/*        quantity: 1,*/}
                          {/*        weight: 0,*/}
                          {/*        unit: BlockExerciseUnit.REPS,*/}
                          {/*        weightType: BlockExerciseWeightType.KG,*/}
                          {/*      });*/}
                          {/*    }}*/}
                          {/*    loading={exercisesLoading}*/}
                          {/*    options={exercises?.getExercises*/}
                          {/*        ?.map(e => {*/}
                          {/*          // console.log(e);*/}
                          {/*          return e;*/}
                          {/*        })*/}
                          {/*        ?.filter(*/}
                          {/*            (x) =>*/}
                          {/*                !!{ "pilatesr": true}[x.type?.toLowerCase()] &&*/}
                          {/*                !x.coach,*/}
                          {/*        )*/}
                          {/*        .map((ex) => ({*/}
                          {/*          ...ex,*/}
                          {/*          label: ex.title,*/}
                          {/*          value: ex.uid,*/}
                          {/*        }))}*/}
                          {/*    style={{ margin: '0', maxHeight: '4rem' }}*/}
                          {/*    error={*/}
                          {/*        !block?.exercises?.length &&*/}
                          {/*        touched?.blocks?.[index] &&*/}
                          {/*        errors?.blocks?.[index]?.exercises*/}
                          {/*    }*/}
                          {/*/>*/}
                        </ExerciseContent>
                    )}
                    {(block?.type !== WorkoutBlockType.RUN && block?.type !== WorkoutBlockType.PILATESR) && (
                      <ExerciseContent>
                        {(block?.type !== WorkoutBlockType.STRENGTH ||
                          !block?.exercises?.length) && (
                          <CustomSearch
                            placeholder="Search Exercise"
                            onChange={(v, { action }) => {
                              if (action === 'input-change' || action === 'set-value') {
                                setSearchText(v);
                              }
                            }}
                            onSelect={(v) => {
                              if (block?.type === WorkoutBlockType.STRENGTH) {
                                const setsQuantity = block?.attributes?.find(
                                  (x) => x.name === 'sets',
                                )?.value;

                                const exercisesToAdd = new Array(Number(setsQuantity)).fill({
                                  exercise: v,
                                  quantity: 1,
                                  weight: 0,
                                  notes: "",
                                  unit: BlockExerciseUnit.REPS,
                                  weightType: BlockExerciseWeightType.KG,
                                });
                                setFieldValue(`blocks[${index}].exercises`, exercisesToAdd);
                              } else {
                                const [, workM, workS] =
                                  block?.type === WorkoutBlockType.INTERVAL
                                    ? block?.attributes
                                        ?.find((x) => x.name === 'workTime')
                                        ?.value?.split(':')
                                    : '';
                                const quantity =
                                  block?.type === WorkoutBlockType.INTERVAL
                                    ? workM * 60 + Number(workS)
                                    : 1;
                                const unit =
                                  block.type === WorkoutBlockType.RUN ||
                                  block.type === WorkoutBlockType.INTERVAL
                                    ? BlockExerciseUnit.SECONDS
                                    : BlockExerciseUnit.REPS;
                                const numberOfSets =
                                  block?.type === WorkoutBlockType.CLASSICSTRENGTH ? 3 : null;
                                const restTime =
                                  block?.type === WorkoutBlockType.CLASSICSTRENGTH
                                    ? '00:00:30'
                                    : null;
                                push({
                                  exercise: v,
                                  quantity,
                                  weight: 0,
                                  unit,
                                  notes: "",
                                  weightType: BlockExerciseWeightType.KG,
                                  numberOfSets,
                                  restTime,
                                });
                              }
                            }}
                            createNew={(searchInput) =>
                              setExerciseAdding({ title: searchInput, searchText })
                            }
                            viewOption={(option) => setExerciseAdding({ ...option, searchText })}
                            options={exercises?.getExercises?.filter(
                                (x) =>
                                    !{ "pilatesr": true}[x.type?.toLowerCase()]
                            ) || []}
                            value={searchText || ''}
                            style={{ margin: '0' }}
                            loading={exercisesLoading}
                            error={
                              !block?.exercises?.length &&
                              touched?.blocks?.[index] &&
                              errors?.blocks?.[index]?.exercises
                            }
                          />
                        )}
                      </ExerciseContent>
                    )}
                  </ExerciseWrapper>
                  {/* )} */}
                </>
              )}
            ></FieldArray>
            <AttributeWrapper>
              <AttributeLabel>
                <AttributeBullet />
                Comments & Indications
              </AttributeLabel>
              <Field id={`blocks[${index}].comment`} name={`blocks[${index}].comment`}>
                {({ field }) => (
                  <CustomTextArea
                    rowCount={null}
                    style={{
                      height: '68px',
                      padding: '4px',
                      maxHeight: '68px',
                    }}
                    wrapperStyle={{
                      maxWidth: 'min(70%, Calc(100% - 18.6rem))',
                    }}
                    onChange={(v) => setFieldValue(field.name, v)}
                    value={field.value}
                    error={touched?.blocks?.[index] && errors?.blocks?.[index]?.comment}
                  />
                )}
              </Field>
            </AttributeWrapper>
          </>
        )}
        <WorkoutBlockFooter>
          <div>
            {block?.type !== 'REST' && (
              <>
                {index === 0 && (
                  <Field id={`blocks[${index}].isWarmUp`} name={`blocks[${index}].isWarmUp`}>
                    {({ field }) => (
                      <AttributeLabel flexGrow>
                        <AttrCheckBox
                          active={field.value}
                          onClick={() => setFieldValue(field.name, !field.value)}
                        />
                        Mark this block as Warmup
                      </AttributeLabel>
                    )}
                  </Field>
                )}
                <Field
                  id={`blocks[${index}].videoRequested`}
                  name={`blocks[${index}].videoRequested`}
                >
                  {({ field }) => (
                    <AttributeLabel flexGrow>
                      <AttrCheckBox
                        active={field.value}
                        onClick={() => setFieldValue(field.name, !field.value)}
                      />
                      Request Video
                    </AttributeLabel>
                  )}
                </Field>
              </>
            )}
          </div>
          <WorkoutBlockApplyButton>
            <CustomButton
              outlined
              outlineColor="#00FF91"
              backgroundColor="white"
              fontSize="1.4rem"
              type="button"
              onClick={async () => {
                await setTouched({ blocks: { [index]: true } });
                const invalid = !!errors?.blocks?.[index];
                if (!invalid) {
                  setFieldValue('editingBlockIndex', null);
                }
              }}
            >
              Apply
            </CustomButton>
          </WorkoutBlockApplyButton>
        </WorkoutBlockFooter>
      </WorkoutBlockContent>
    </WorkoutBlockForm>
  );
};

export default EdittingWorkoutBlock;
